import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
axios.defaults.baseURL = process.env.REACT_APP_TRAVIFAI_SERVER_BACKEND_URL;
// axios.defaults.baseURL = "https://api.thezonzo.com/api/v1/"
// axios.defaults.baseURL = "https://staging-api-new.thezonzo.com/api/v1"

var url = process.env.REACT_APP_TRAVIFAI_SERVER_BACKEND_URL;

console.log(
  "process.env.REACT_APP_ZONZO_SERVER_BACKEND_URL",
  process.env.REACT_APP_TRAVIFAI_SERVER_BACKEND_URL
);

const setJWT = () => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${cookies.get(
    "token"
  )}`;
  // axios.defaults.headers.common["Access-Control-Allow-Origin"] =  "*";
};
// const setJWT = () => {
//   axios.defaults.headers.common["Authorization"] = `Bearer ${
//     localStorage.getItem("token")
//   }`;
//   // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// };
setJWT();
axios.interceptors.response.use(null, (err) => {
  const expectedError =
    err.response && err.response.status >= 400 && err.response.status < 500;
  if (!expectedError) {
    // toast.error("Unexpected error occured, please Try again");
  }
  return Promise.reject(err);
});
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  setJWT,
};
