import React from "react";

function Blog() {
  return (
    <div>
      <div class="grid grid-cols-12 gap-4 relative">
        <div class="col-span-12 ">
          <section class="pt-10 h-screen overflow-hidden bg-gray-50 md:pt-0 sm:pt-16 2xl:pt-16">
            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
              <div class="grid items-center grid-cols-1 md:grid-cols-2">
                <div class="relative">
                  {" "}
                  <img
                    class="relative rounded-lg w-full xl:max-w-sm xl:mx-auto 2xl:origin-bottom 2xl:scale-110"
                    src="/images/ankur.png"
                    alt=""
                  />
                </div>
                <div>
                  <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
                    Hey{" "}
                    <span className="text-blue-500">
                      Chat with Travifai.com
                    </span>
                  </h2>
                  <p class="max-w-lg mt-3 text-xl leading-relaxed text-gray-600 md:mt-8">
                    Waiting ...
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Blog;
