import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

function Header() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  console.log("opeen", open);
  const menuItem = [
    {
      heading: "About Us",
      navigate: "/about-us",
    },
    {
      heading: "Product",
      navigate: "/features",
    },
    {
      heading: "Blog",
      navigate: "/blogs",
    },
    {
      heading: "Become a Partner",
      navigate: "/partner",
    },
  ];
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={() => setOpen(!open)}>
      <List className="!mt-5 ">
        {menuItem &&
          menuItem.map((text, index) => (
            <ListItem className="mt-5" key={index} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText className={`${text.heading == "Become a Partner" ? "!text-yellow-500" : "null"}`}
                  primary={text.heading}
                  onClick={() => {
                    navigate(text.navigate);
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <div class="grid grid-cols-12 gap-4 relative z-10">
        <div class="col-span-12 ">
          <header class="bg-white shadow-white">
            <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <nav class="flex items-center justify-between h-16 lg:h-20">
                <div class="flex-shrink-0 ">
                  <a href="/" title="" class="flex">
                    <img class="w-40 " src="/images/logo.jpg" alt="" />
                  </a>
                </div>

                <button
                  type="button"
                  class="inline-flex p-2 text-black transition-all duration-200 rounded-md md:hidden "
                  onClick={() => setOpen(!open)}
                >
                  <svg
                    class="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                </button>
                <Drawer open={open} onClose={() => setOpen(!open)}>
                  {DrawerList}
                </Drawer>

                <div class="hidden md:flex md:items-center md:space-x-10">
                  <a
                    href="#"
                    title=""
                    class="text-sm font-medium text-black transition-all duration-200 lg:text-base hover:text-opacity-70 focus:text-opacity-70"
                    onClick={() => navigate("/about-us")}
                  >
                    {" "}
                    About Us{" "}
                  </a>
                  <a
                    href="#"
                    title=""
                    class="text-sm font-medium text-black transition-all duration-200 lg:text-base hover:text-opacity-70 focus:text-opacity-70"
                    onClick={() => navigate("/features")}
                  >
                    {" "}
                    Product{" "}
                  </a>

                  <a
                    href="#"
                    title=""
                    class="text-sm font-medium text-black transition-all duration-200 lg:text-base hover:text-opacity-70 focus:text-opacity-70"
                    onClick={() => navigate("/blogs")}
                  >
                    {" "}
                    Blog{" "}
                  </a>

                  <a
                    href="#"
                    title=""
                    class="text-sm font-medium text-black transition-all duration-200 lg:text-base hover:text-opacity-70 focus:text-opacity-70"
                    onClick={() => navigate("/partner")}
                  >
                    {" "}
                    Become a Partner{" "}
                  </a>

                  {/* <a
                    href="#"
                    title=""
                    class="text-sm font-medium text-black transition-all duration-200 lg:text-base hover:text-opacity-70 focus:text-opacity-70"
                  >
                    {" "}
                    Pricing{" "}
                  </a> */}
                </div>
              </nav>
            </div>
          </header>
        </div>
      </div>
    </div>
  );
}

export default Header;
