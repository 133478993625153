import React from "react";

function AboutUs() {
  return (
    <div>
      <div class="grid grid-cols-12 gap-4 relative">
        <div class="col-span-12 ">
          <section class="pt-10 overflow-hidden bg-gray-50 md:pt-0 sm:pt-16 2xl:pt-16">
            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
              <div class="grid items-center grid-cols-1 md:grid-cols-2">
                <div>
                  <h2 class="text-3xl text-[#cdac51] font-bold leading-tight text-black sm:text-2xl lg:text-3xl">
                    About Travifai
                  </h2>
                  <p class="max-w-lg mt-3 text-xl leading-relaxed text-gray-600 md:mt-8">
                    Founded by Ankur Kumar in 2024, TravifAi.com is a dynamic
                    startup dedicated to transforming the travel ecosystem
                    through innovative, cloud-based solutions tailored for
                    travelers, travel agencies, and hoteliers. With over a
                    decade of experience in client servicing and sales, Ankur
                    recognized the inefficiencies within the travel industry and
                    sought to create a platform that bridges the gaps,
                    delivering value and efficiency. TravifAi offers
                    cutting-edge SaaS tools designed specifically for travel
                    agencies and hoteliers, allowing them to streamline
                    operations, reduce costs, and expand their market reach. Our
                    platform facilitates real-time connections between global
                    travel agents and hoteliers, empowering them to manage
                    property inventories, bookings, and customer relationships
                    seamlessly. For travelers, TravifAi provides a user-friendly
                    platform that offers highly competitive room rates,
                    personalized booking options, and enhanced property
                    visibility, promoting domestic tourism while ensuring an
                    affordable, tailored experience. Join us as we revolutionize
                    the travel industry with solutions that cater to every
                    stakeholder, driving efficiency, growth, and value for all.
                  </p>

                  <p class="mt-4 text-xl text-gray-600 md:mt-8">
                    <span class="relative inline-block">
                      <span class="absolute inline-block w-full bottom-0.5 h-2 bg-yellow-300"></span>
                      <span class="relative"> Have a question? </span>
                    </span>
                    <br class="block sm:hidden" />
                    Ask me on{" "}
                    <a
                      href="mailto:ak@travifai.com"
                      title=""
                      class="transition-all duration-200 text-sky-500 hover:text-sky-600 hover:underline"
                    >
                      Gmail
                    </a>
                  </p>
                </div>

                <div class="relative  rounded-[100%] cover">
                  <img
                    class="relative w-full rounded-[100%] xl:max-w-lg xl:mx-auto 2xl:origin-bottom 2xl:scale-110"
                    src="/images/travifai_new.jpg"
                    style={{ width: "100%", height: "100%" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
