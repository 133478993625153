import React from "react";

function Landing() {
  return (
    <div>
      <div class="grid grid-cols-12 gap-4 relative">
        <div class="col-span-12 ">
          <div class="overflow-x-hidden bg-gray-50">
            <section class="pt-12 bg-gray-50 sm:pt-16">
              <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div class="max-w-4xl mx-auto text-center">
                  <h1 class="px-6 text-lg text-gray-600 font-inter">
                    Instant connect of travel agencies and hotelier
                  </h1>
                  <p class="mt-5 text-4xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-6xl lg:leading-tight font-pj">
                    Zero Commision hotel deals
                  </p>
                  <span class="relative inline-flex sm:inline sm:text-xl lg:text-2xl">
                    <span class="bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] blur-lg filter opacity-30 w-full h-full absolute inset-0"></span>
                    <span class="relative">
                      {" "}
                      Real time agency connections and cloud solutions for
                      seamless property and bussiness management.
                    </span>
                  </span>

                  <div class="px-8 sm:items-center sm:justify-center sm:px-0 sm:space-x-5 sm:flex mt-9">
                    {/* <a
                      href="#"
                      title=""
                      class="inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                      role="button"
                    >
                      Get more customers
                    </a> */}

                    <a
                      // href="https://drive.google.com/file/d/1yucwLOmLgcQgs7SNY4XuEwo31kIa6pAh/view"
                      target="_blank"
                      title=""
                      class="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-lg font-bold text-gray-900 transition-all duration-200 border-2 border-gray-400 sm:w-auto sm:mt-0 rounded-xl font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-900 focus:bg-gray-900 hover:text-white focus:text-white hover:border-gray-900 focus:border-gray-900"
                      role="button"
                      onClick={() => {
                        console.log(
                          "height",
                          document.documentElement.scrollHeight
                        );
                        window.scrollTo({
                          top: document.documentElement.scrollHeight - 1450,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <svg
                        class="w-5 h-5 mr-2"
                        viewBox="0 0 18 18"
                        fill="none"
                        stroke="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.18003 13.4261C6.8586 14.3918 5 13.448 5 11.8113V5.43865C5 3.80198 6.8586 2.85821 8.18003 3.82387L12.5403 7.01022C13.6336 7.80916 13.6336 9.44084 12.5403 10.2398L8.18003 13.4261Z"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Register for free demo
                    </a>
                  </div>

                  <p class="mt-8 text-base text-gray-500 font-inter">
                    Not burden of extra charges on travelers just pay for room
                    bookings.
                  </p>
                </div>
              </div>

              <div class="pb-12 bg-white">
                <div class="relative">
                  <div class="absolute inset-0 h-2/3 bg-gray-50"></div>
                  <div class="relative mx-auto">
                    <div class="lg:max-w-5xl lg:mx-auto">
                      <img
                        class="transform scale-110"
                        src="/images/ss.png"
                        alt=""
                        className="w-[100%]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
