import React, { useState } from "react";

function Features() {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div class="grid grid-cols-12 gap-4 relative">
        <div class="col-span-12 ">
          <section class="py-10 bg-white sm:py-16 lg:py-24">
            <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div class="grid grid-cols-1 gap-12 text-center sm:grid-cols-2 md:grid-cols-3 lg:gap-y-16">
                <div>
                  <div class="relative flex items-center justify-center mx-auto">
                    <svg
                      class="text-blue-100"
                      width="72"
                      height="75"
                      viewBox="0 0 72 75"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z" />
                    </svg>
                    <svg
                      class="absolute text-blue-600 w-9 h-9"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                      />
                    </svg>
                  </div>
                  <h3 class="mt-8 text-lg font-semibold text-black">
                    Commission Less Online Booking Platform
                  </h3>
                  <p class="mt-4 text-base text-gray-600">
                    We do not charge any commission fees to hoteliers,
                    travelers, or travel agents on any of our deals. Our
                    platform ensures that all transactions are completely free
                    of commission, allowing you to enjoy seamless and
                    cost-effective bookings without any additional charges.
                  </p>
                </div>

                <div>
                  <div class="relative flex items-center justify-center mx-auto">
                    <svg
                      class="text-orange-100"
                      width="62"
                      height="64"
                      viewBox="0 0 62 64"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z" />
                    </svg>
                    <svg
                      class="absolute text-orange-600 w-9 h-9"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                  </div>
                  <h3 class="mt-8 text-lg font-semibold text-black">
                    H & T Connect
                  </h3>
                  <p class="mt-4 text-base text-gray-600">
                    Our platform facilitates direct communication between
                    hoteliers and travel agents/agencies, enabling seamless
                    interactions regarding booking inquiries, inventory
                    availability, price negotiations, quotation exchanges, and
                    deal closures. This streamlined approach allows both parties
                    to efficiently manage and finalize arrangements without
                    intermediaries.
                  </p>
                </div>

                <div>
                  <div class="relative flex items-center justify-center mx-auto">
                    <svg
                      class="text-green-100"
                      width="66"
                      height="68"
                      viewBox="0 0 66 68"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M65.5 30C65.5 50.4345 46.4345 68 26 68C5.56546 68 0 50.4345 0 30C0 9.56546 12.5655 0 33 0C53.4345 0 65.5 9.56546 65.5 30Z" />
                    </svg>
                    <svg
                      class="absolute text-green-600 w-9 h-9"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                      />
                    </svg>
                  </div>
                  <h3 class="mt-8 text-lg font-semibold text-black">
                    Rate Manager
                  </h3>
                  <p class="mt-4 text-base text-gray-600">
                    This feature allows you to post rates on the Travifai
                    booking platform as well as other multiple online booking
                    platforms for single or multiple days with just a few
                    clicks. It helps you save both time and effort, streamlining
                    the rate management process.
                  </p>
                </div>

                <div>
                  <div class="relative flex items-center justify-center mx-auto">
                    <svg
                      class="text-purple-100"
                      width="66"
                      height="68"
                      viewBox="0 0 66 68"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M65.5 30C65.5 50.4345 46.4345 68 26 68C5.56546 68 0 50.4345 0 30C0 9.56546 12.5655 0 33 0C53.4345 0 65.5 9.56546 65.5 30Z" />
                    </svg>
                    <svg
                      class="absolute text-purple-600 w-9 h-9"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                      />
                    </svg>
                  </div>
                  <h3 class="mt-8 text-lg font-semibold text-black">
                    Inventory Manager
                  </h3>
                  <p class="mt-4 text-base text-gray-600">
                    This feature allows you to post inventory on the Travifai
                    booking platform, as well as on multiple other online
                    booking platforms, for both single and multiple days with
                    just a few clicks. It significantly saves time and effort by
                    streamlining the inventory management process.
                  </p>
                </div>

                <div>
                  <div class="relative flex items-center justify-center mx-auto">
                    <svg
                      class="text-gray-100"
                      width="65"
                      height="70"
                      viewBox="0 0 65 70"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M64.5 26C64.5 46.4345 56.4345 70 36 70C15.5655 70 0 53.9345 0 33.5C0 13.0655 13.0655 0 33.5 0C53.9345 0 64.5 5.56546 64.5 26Z" />
                    </svg>
                    <svg
                      class="absolute text-gray-600 w-9 h-9"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                  </div>
                  <h3 class="mt-8 text-lg font-semibold text-black">
                    Unified Booking Manager
                  </h3>
                  <p class="mt-4 text-base text-gray-600">
                    This feature allows you to manage, create, edit, and delete
                    both online and offline bookings from a single screen,
                    across one or multiple platforms. It streamlines your
                    booking operations by consolidating all tasks into one
                    convenient interface.
                  </p>
                </div>

                <div>
                  <div class="relative flex items-center justify-center mx-auto">
                    <svg
                      class="text-yellow-100"
                      width="78"
                      height="78"
                      viewBox="0 0 78 78"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8.49996 28.0002C4.09993 47.9554 14.1313 66.7885 35.5 71.5002C56.8688 76.2119 68.0999 58.4553 72.5 38.5001C76.9 18.5449 68.3688 12.711 47 7.99931C25.6312 3.28759 12.9 8.04499 8.49996 28.0002Z" />
                    </svg>
                    <svg
                      class="absolute text-yellow-500 w-9 h-9"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                      />
                    </svg>
                  </div>
                  <h3 class="mt-8 text-lg font-semibold text-black">
                    DIT (Daily Insights on Tips)
                  </h3>
                  <p class="mt-4 text-base text-gray-600">
                    Today's check-ins, check-outs, bookings, revenue, and
                    cancellations, as well as today’s offline bookings, are all
                    accessible in one place. This comprehensive overview helps
                    you track revenue growth month-over-month and build annual
                    sales projections. Additionally, it allows you to monitor
                    monthly expenses and create annual expense forecasts. These
                    insights are crucial for understanding your business’s
                    Profit and Loss (P&L) and making informed financial
                    decisions.
                  </p>
                </div>
                {show && (
                  <>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-gray-100"
                          width="62"
                          height="64"
                          viewBox="0 0 62 64"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z"></path>
                        </svg>
                        <svg
                          class="absolute text-gray-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Preview Manager
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Instant and convenient access to both property and
                        personal domain links.
                      </p>
                    </div>

                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-rose-100"
                          width="72"
                          height="75"
                          viewBox="0 0 72 75"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z" />
                        </svg>
                        <svg
                          class="absolute text-rose-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Booking engine
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Our booking engine seamlessly integrates with your
                        personal domain's Property Management System (PMS). This
                        integration ensures that all booking data is
                        automatically synchronized, providing a unified platform
                        for managing reservations, updating availability, and
                        streamlining operations. By connecting the booking
                        engine directly with your PMS, you can enhance
                        efficiency and accuracy in managing your property’s
                        bookings and overall operations.
                      </p>
                    </div>

                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-lime-100"
                          width="62"
                          height="65"
                          viewBox="0 0 62 65"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 13.0264C0 33.4609 8.06546 64.0264 28.5 64.0264C48.9345 64.0264 62 50.4604 62 30.0259C62 9.59135 59.4345 4.0256 39 4.0256C18.5655 4.0256 0 -7.40819 0 13.0264Z" />
                        </svg>

                        <svg
                          class="absolute text-lime-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M13 10V3L4 14h7v7l9-11h-7z"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Payment integrations
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Our solution offers seamless integration with your
                        personal website, allowing you to embed booking
                        functionality directly into your site. This integration
                        ensures a cohesive user experience, enabling visitors to
                        view availability, make reservations, and manage
                        bookings without leaving your website. By connecting
                        your personal website with our system, you streamline
                        the booking process and enhance your site's
                        functionality, making it easier for customers to engage
                        with your services.
                      </p>
                    </div>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-blue-100"
                          width="72"
                          height="75"
                          viewBox="0 0 72 75"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z" />
                        </svg>
                        <svg
                          class="absolute text-blue-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Meta Search/Engine
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Advertisment:- Agregate search results for hotoelrs and
                        travel agents.
                      </p>
                    </div>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-lime-100"
                          width="62"
                          height="65"
                          viewBox="0 0 62 65"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 13.0264C0 33.4609 8.06546 64.0264 28.5 64.0264C48.9345 64.0264 62 50.4604 62 30.0259C62 9.59135 59.4345 4.0256 39 4.0256C18.5655 4.0256 0 -7.40819 0 13.0264Z" />
                        </svg>

                        <svg
                          class="absolute text-lime-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M13 10V3L4 14h7v7l9-11h-7z"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Marketing Automation
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Digital marketing:- Share promotional offers, seasonal
                        offer to the existing customer data base and to share on
                        social platform and create social profiles
                      </p>
                    </div>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-rose-100"
                          width="72"
                          height="75"
                          viewBox="0 0 72 75"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z" />
                        </svg>
                        <svg
                          class="absolute text-rose-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Create B2B/D2C Proposal
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Creating online intstant proposals or quotatioins for
                        guests.
                      </p>
                    </div>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-gray-100"
                          width="62"
                          height="64"
                          viewBox="0 0 62 64"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z"></path>
                        </svg>
                        <svg
                          class="absolute text-gray-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        User Management
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Manage your staff and the performance on real time
                      </p>
                    </div>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-orange-100"
                          width="62"
                          height="64"
                          viewBox="0 0 62 64"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z" />
                        </svg>
                        <svg
                          class="absolute text-orange-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M13 10V3L4 14h7v7l9-11h-7z"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Billing Management
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Manage all your internal external bills in just few
                        clicks
                      </p>
                    </div>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-lime-100"
                          width="62"
                          height="65"
                          viewBox="0 0 62 65"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 13.0264C0 33.4609 8.06546 64.0264 28.5 64.0264C48.9345 64.0264 62 50.4604 62 30.0259C62 9.59135 59.4345 4.0256 39 4.0256C18.5655 4.0256 0 -7.40819 0 13.0264Z" />
                        </svg>

                        <svg
                          class="absolute text-lime-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M13 10V3L4 14h7v7l9-11h-7z"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">POS</h3>
                      <p class="mt-4 text-base text-gray-600">
                        Enables seamless billing and transaction management,
                        integrating with property management systems to
                        streamline payment processing and enhance operational
                        efficiency for hoteliers and travel agencies.
                      </p>
                    </div>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-rose-100"
                          width="72"
                          height="75"
                          viewBox="0 0 72 75"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z" />
                        </svg>
                        <svg
                          class="absolute text-rose-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Reputation Builder
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Our Reputation Builder enhances hospitality, analyzes
                        customer reviews, and mitigates negative feedback across
                        channels.
                      </p>
                    </div>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-lime-100"
                          width="62"
                          height="65"
                          viewBox="0 0 62 65"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 13.0264C0 33.4609 8.06546 64.0264 28.5 64.0264C48.9345 64.0264 62 50.4604 62 30.0259C62 9.59135 59.4345 4.0256 39 4.0256C18.5655 4.0256 0 -7.40819 0 13.0264Z" />
                        </svg>

                        <svg
                          class="absolute text-lime-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M13 10V3L4 14h7v7l9-11h-7z"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Central Reservation System
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Our Central Reservation System provides instant room
                        visibility and guest requirements for both hoteliers and
                        travel agencies.
                      </p>
                    </div>

                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-yellow-100"
                          width="78"
                          height="78"
                          viewBox="0 0 78 78"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.49996 28.0002C4.09993 47.9554 14.1313 66.7885 35.5 71.5002C56.8688 76.2119 68.0999 58.4553 72.5 38.5001C76.9 18.5449 68.3688 12.711 47 7.99931C25.6312 3.28759 12.9 8.04499 8.49996 28.0002Z" />
                        </svg>
                        <svg
                          class="absolute text-yellow-500 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Man Power Management
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Manpower Management:- Our Manpower Management feature
                        addresses staffing challenges by showcasing job posts
                        and enabling real-time interviews with candidates,
                        streamlining the hiring process for hoteliers and travel
                        agencies.
                      </p>
                    </div>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-gray-100"
                          width="62"
                          height="64"
                          viewBox="0 0 62 64"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z"></path>
                        </svg>
                        <svg
                          class="absolute text-gray-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Reports & Analysis
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Understand the revenue growth month on month, this help
                        to build projections anually for sales growth, this
                        helps to understand expenses month on month, this helpts
                        to build expense projections anually, this gives you
                        insights to understand your business P&L
                      </p>
                    </div>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-orange-100"
                          width="62"
                          height="64"
                          viewBox="0 0 62 64"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z" />
                        </svg>
                        <svg
                          class="absolute text-orange-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M13 10V3L4 14h7v7l9-11h-7z"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Notification Manager
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Gives you instant notifications every time when booking
                        created on travifai or other ota, this gives you
                        notification on cancellations of bookings and other
                        notifications like which staff is on leave, any kitchen
                        inventory need attention any housekeepign requirement,
                        any alarming situation, this notification manager even
                        helps you to reach out to guest and for promotional
                        deals and to chat with them.
                      </p>
                    </div>
                    <div>
                      <div class="relative flex items-center justify-center mx-auto">
                        <svg
                          class="text-lime-100"
                          width="62"
                          height="65"
                          viewBox="0 0 62 65"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 13.0264C0 33.4609 8.06546 64.0264 28.5 64.0264C48.9345 64.0264 62 50.4604 62 30.0259C62 9.59135 59.4345 4.0256 39 4.0256C18.5655 4.0256 0 -7.40819 0 13.0264Z" />
                        </svg>

                        <svg
                          class="absolute text-lime-600 w-9 h-9"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M13 10V3L4 14h7v7l9-11h-7z"
                          />
                        </svg>
                      </div>
                      <h3 class="mt-8 text-lg font-semibold text-black">
                        Customer support 24*7
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Connect directly with the leadership team to share the
                        complaints and to get assistance, through chat and
                        calls.
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-center mt-5">
              <button
                type="button"
                onClick={() => setShow(!show)}
                class="m-auto focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5  mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                {show ? "Show Less" : "Show More"}
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Features;
