import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { register } from "../services/data/register";
import { toast } from "react-toastify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const names = [
  "MMT",
  "Yatra",
  "Agoda",
  "Goibibo",
  "Booking.com",
  "Expedia",
  "Hotels.com",
  "Airbnb",
  "Travelguru",
  "HRS.com",
  "Airbnb.com",
  "Trip.com",
  "Others",
];

const RegistrationForm = () => {
  const [isFirstForm, setIsFirstForm] = useState(true);
  const [form, setForm] = useState({
    name: "",
    mobile: "",
    email: "",
    professionType: "",
    bussinessName: "",
  });

  const toggleForm = (value) => {
    setIsFirstForm(value);
  };
  console.log("isfirst", isFirstForm);
  const [otas, setOtas] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setOtas(typeof value === "string" ? value.split(",") : value);
  };
  const formChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      ...form,
      OTAs: otas,
      registerType: isFirstForm ? "Hotelier" : "TravelAgent",
    };
    await register(payload).then((res) => {
      toast.success(res?.message);
      setForm({
        name: "",
        mobile: "",
        email: "",
        professionType: "",
        bussinessName: "",
      });
    });
  };

  return (
    <div className="mt-4">
      {/* Toggle Buttons */}

      <div className="flex justify-center mb-6">
        <button
          className={`px-4 py-2 rounded-l-md focus:outline-none ${
            isFirstForm
              ? "text-white bg-orange-500 hover:bg-orange-600"
              : "text-gray-500 bg-gray-200 hover:bg-orange-600"
          }`}
          onClick={() => toggleForm(true)}
        >
          Hotelier
        </button>
        <button
          className={`px-4 py-2 rounded-r-md focus:outline-none ${
            !isFirstForm
              ? "text-white bg-orange-500 hover:bg-orange-600"
              : "text-gray-500 bg-gray-200 hover:bg-orange-600"
          }`}
          onClick={() => toggleForm(false)}
        >
          Travel Agency
        </button>
      </div>

      <div className={`transition-opacity duration-500 `}>
        {isFirstForm ? (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <div className="mt-2.5 relative">
                <input
                  type="text"
                  name="name"
                  onChange={formChange}
                  value={form.name}
                  placeholder="Enter your full name"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="text"
                  name="mobile"
                  onChange={formChange}
                  placeholder="Contact Number"
                  value={form.mobile}
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="email"
                  name="email"
                  onChange={formChange}
                  value={form.email}
                  placeholder="Contact Email"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="text"
                  name="professionType"
                  onChange={formChange}
                  value={form.professionType}
                  placeholder="Full Time Profession"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <div className="w-full ">
                  <FormControl className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500">
                    <Select
                      multiple
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      displayEmpty
                      value={otas}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>OTA's</em>;
                        }

                        return selected.join(", ");
                      }}
                      sx={{
                        // ".MuiOutlinedInput-notchedOutline": {
                        //   borderColor: "#E34824",
                        // },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E34824",
                          borderWidth: "thin",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E34824",
                          borderWidth: "thin",
                        },
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em>OTA's</em>
                      </MenuItem>
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={otas.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all duration-200 bg-orange-500 border border-transparent rounded-md focus:outline-none hover:bg-orange-600 focus:bg-orange-600"
              >
                Register
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <div className="mt-2.5 relative">
                <input
                  type="text"
                  name="name"
                  onChange={formChange}
                  value={form.name}
                  placeholder="Enter your full name"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="text"
                  name="bussinessName"
                  value={form.bussinessName}
                  onChange={formChange}
                  placeholder="Business Name"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="text"
                  name="mobile"
                  onChange={formChange}
                  value={form.mobile}
                  placeholder="Contact Number"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="email"
                  name="email"
                  onChange={formChange}
                  value={form.email}
                  placeholder="Contact Email"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="text"
                  name="professionType"
                  onChange={formChange}
                  value={form.professionType}
                  placeholder="Full Time Profession"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <div className="w-full ">
                  <FormControl className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500">
                    <Select
                      multiple
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      displayEmpty
                      value={otas}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>OTA's</em>;
                        }

                        return selected.join(", ");
                      }}
                      sx={{
                        // ".MuiOutlinedInput-notchedOutline": {
                        //   borderColor: "#E34824",
                        // },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E34824",
                          borderWidth: "thin",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E34824",
                          borderWidth: "thin",
                        },
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em>OTA's</em>
                      </MenuItem>
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={otas.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all duration-200 bg-orange-500 border border-transparent rounded-md focus:outline-none hover:bg-orange-600 focus:bg-orange-600"
              >
                Register
              </button>
            </div>
          </form>
        )}

        {/* {!isFirstForm && (
          <form action="#" method="POST" className="space-y-6">
            <div>
              <div className="mt-2.5 relative">
                <input
                  type="text"
                  placeholder="Enter your full name"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="text"
                  placeholder="Business Name"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="text"
                  placeholder="Contact Number"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="email"
                  placeholder="Contact Email"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="text"
                  placeholder="Full Time Profession"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5 relative">
                <input
                  type="number"
                  placeholder="No. Of Hotels Connected With"
                  className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all duration-200 bg-orange-500 border border-transparent rounded-md focus:outline-none hover:bg-orange-600 focus:bg-orange-600"
              >
                Register
              </button>
            </div>
          </form>
        )} */}
      </div>
    </div>
  );
};

export default RegistrationForm;
