import { toast } from "react-toastify";
import httpService from "../httpservice/httpService";

export async function register(payload) {
  try {
    const { data } = await httpService.post("/users/register-visiter", payload);
    return data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
}
