import React from "react";
import { useNavigate } from "react-router-dom";

function BecomePartnet() {
  const navigate = useNavigate();
  const handleNavigate = (scrollPosition) => {
    navigate('/', { state: { scrollPosition } });
  };

  return (
    <div>
      <div class="grid grid-cols-12 gap-4 relative">
        <div class="col-span-12 ">
          <section class="py-12 bg-gray-50 sm:py-16 lg:py-20">
            <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div class="flex flex-col items-center">
                <div class="text-center">
                  <h2 class="mt-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">
                    Why should you partner with Travifai ?
                  </h2>
                  <p class="mt-5 text-lg font-medium text-gray-600 font-pj">
                    Partner with Travifai for just{" "}
                    <span className="bg-yellow-300 ">₹399</span> annually and
                    enjoy 0% commission on all bookings!
                  </p>
                </div>

                <div class="relative mt-10 md:mt-24 md:order-2">
                  <div class="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                    <div
                      class="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"
                      //   style="background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)"
                    ></div>
                  </div>

                  <div class="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-2">
                    <div class="flex flex-col overflow-hidden shadow-xl">
                      <div class="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                        <div class="flex-1">
                          <p class="text-lg font-medium text-gray-600 font-pj text-center">
                            Travel Agent
                          </p>
                          <blockquote class="flex-1 mt-8">
                            <ul className="list-disc text-[#fff] ml-5 mt-5">
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                Instant connect with hoteliers PAN India.
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                Real time Access to large scale inventories.
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                User friendly paltform to show case your
                                properties and other products & services.
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                ⁠Connect directly with Travelers
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                ⁠Get Relevant inquiries
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                ⁠Relief from extra commission on bookings
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                ⁠Instant transparent communication with
                                travelers & hoteliers.
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                ⁠Cloud based solution like channel manager, pms,
                                pos, booking engines, personal domain
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                Real time cloud based business performance
                                tracking system
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                Get real time expense and revenue insights
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                Much more
                              </li>
                            </ul>
                          </blockquote>
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-col overflow-hidden shadow-xl">
                      <div class="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                        <div class="flex-1">
                          <p class="text-lg font-medium text-gray-600 font-pj text-center">
                            Hoteliers
                          </p>

                          <blockquote class="flex-1 mt-8">
                            <ul className="list-disc text-[#fff] ml-5 mt-5">
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                Commission less revenue/bookings
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                Instant/Real time connect with Travel agenceis
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                Transparent deals with guest & travel agencies.
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                Cloud based solution like channel manager, pms,
                                pos, booking engines, personal domain
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                Get instant real time business performance
                                insights
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                ⁠Track Revenue & Expenses Real time
                              </li>
                              <li class="text-lg leading-relaxed text-gray-900 font-pj">
                                Much more
                              </li>
                            </ul>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-4 relative">
              <div class="col-span-12 ">
                <section class="py-10 bg-gray-100 sm:py-16 lg:py-24">
                  <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <div class="text-center">
                      <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
                        Unlock the success
                      </h2>
                      {/* <p class="mt-4 text-2xl font-medium">
                        10+ Developers Woeking on it
                      </p> */}

                      <div class="flex flex-col items-center justify-center px-16 mt-8 space-y-4 sm:space-y-0 sm:space-x-4 sm:flex-row lg:mt-12 sm:px-0">
                        <a
                          href="#"
                          title=""
                          class="inline-flex items-center justify-center w-full px-8 py-4 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md sm:w-auto hover:bg-blue-700 focus:bg-blue-700"
                          role="button"
                          onClick={() => handleNavigate(2000)}
                        >
                          {" "}
                          Start Free Registeration{" "}
                        </a>

                        {/* <a
                          href="#"
                          title=""
                          class="inline-flex items-center justify-center w-full px-8 py-4 text-base font-semibold text-black transition-all duration-200 bg-transparent border border-black rounded-md sm:w-auto hover:bg-black hover:text-white focus:bg-black focus:text-white"
                          role="button"
                        >
                          <svg
                            class="w-5 h-5 mr-2 -ml-1"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                            />
                          </svg>
                          Contact Sales
                        </a> */}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default BecomePartnet;
