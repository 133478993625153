import React, { useEffect } from "react";
import Header from "../../components/Header";
import Landing from "../../components/landing";
import Footer from "../../components/footer";
import RegistrationForm from "../../components/RegistrationForm";
import { useLocation } from "react-router-dom";

function HomePage() {
  let Testimonial = [
    {
      image: "/images/deveshwar_kumar.jpeg",
      name: "Mr Deveshwar Kumar Singh",
      designation: "Herb club",
      review:
        "Working with the team behind Travifai.com has been an incredible experience! They’re so passionate about what they do, and the platform they’re building is exactly what we needed. Commission-free bookings? Yes, please! It's going to make it so much easier for us to sell our rooms at a better price. Plus, connecting with travel agencies all over India instantly? Game changer. Can’t wait to see where this goes!",
    },
    {
      image: "/images/kuldeep_tanwar.jpeg",
      name: "Mr Kuldeep Tanwar",
      designation: "Green View Doon",
      review:
        "I’ve been with the Travifai.com team for a while now, and I have to say, I’m impressed. The platform they’re developing is really exciting, especially the zero commission aspect. It's a breath of fresh air in an industry that’s always been so heavy on fees. I’m eager to start using it and see how it helps us reach more customers and grow our business. Big things are coming, no doubt about it!",
    },
    {
      image: "/kuldeep_tanwar.jpeg",
      name: "Miss Anita Pimoli",
      designation: "Hotel Sojourn by the lake ",
      review:
        "Honestly, I'm just so thrilled to be part of this journey with Travifai.com. The idea of a platform that connects us directly with travel agencies across India without any commission is brilliant. This could really help us boost our sales and manage our business more effectively. Plus, their tech solutions for property management seem super handy. Can’t wait for the launch!",
    },
    {
      image: "/kuldeep_tanwar.jpeg",
      name: "Mr Vikas Sharma",
      designation: "Mohini Lake View Cottage",
      review:
        "Been working with the Travifai.com team for a year now, and it’s been fantastic. They’re so dedicated and driven, it’s infectious. Their new platform is something to watch out for—no commissions, direct bookings, and a chance to connect with agencies all over India. This is exactly what we needed to improve our reach and cut costs. Looking forward to the launch, this is going to be huge!",
    },
  ];
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollPosition) {
      window.scrollTo({
        top: location.state.scrollPosition,
        behavior: 'smooth',
      });
    }
  }, [location]);

  return (
    <div>
      <Landing />

      <div class="grid grid-cols-12 gap-4 relative">
        <div class="col-span-12 ">
          <section class="py-24 ">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div class="mb-16 ">
                <span class="text-lg font-medium text-gray-500 text-center block mb-3">
                  Testimonial
                </span>
                <h2 class="text-4xl font-manrope font-bold text-gray-900 text-center">
                  We care about our customers experience too
                </h2>
              </div>
              <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8 max-w-sm sm:max-w-3xl mx-auto xl:max-w-full">
                {Testimonial.map((el) => {
                  return (
                    <div class="group relative w-full bg-gray-50 rounded-2xl py-7 px-5 border border-solid border-transparent transition-all duration-500 hover:border-indigo-600">
                      <div class="w-40 mx-auto">
                        <div class="border-b border-solid border-red-200  mb-5 w-40 h-40 rounded-[100%] overflow-hidden">
                          <img
                            src={el?.image}
                            alt="image"
                            class="w-[100%] h-[100%] object-cover"
                          />
                        </div>
                        <div class="mb-5">
                          <h4 class="text-base text-gray-900 text-center font-medium mb-1">
                            {el?.name}
                          </h4>
                          <span class="text-xs text-center block text-indigo-600  ">
                            {el?.designation}
                          </span>
                        </div>
                        <div class="flex items-center justify-center mb-7 gap-2 text-amber-500 transition-all duration-500  ">
                          <svg
                            class="w-5 h-5"
                            viewBox="0 0 18 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            class="w-5 h-5"
                            viewBox="0 0 18 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            class="w-5 h-5"
                            viewBox="0 0 18 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            class="w-5 h-5"
                            viewBox="0 0 18 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            class="w-5 h-5"
                            viewBox="0 0 18 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="mt-6">
                        <p class="text-sm text-gray-600 text-center leading-6 transition-all duration-500 group-hover:text-gray-800">
                          {el?.review}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 relative">
        <div class="col-span-12 ">
          <section class="py-10 bg-gray-900 sm:py-16 lg:py-24">
            <div class="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
              <div class="grid grid-cols-1 md:items-stretch md:grid-cols-2 gap-x-12 lg:gap-x-20 gap-y-10">
                <div class="flex flex-col justify-between lg:py-5">
                  <div>
                    <h2 class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:leading-tight lg:text-5xl">
                      It’s time to build something exciting!
                    </h2>
                    <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-white font-bold">
                      For Hoteliers:
                    </p>
                    <ul className="list-disc text-[#fff] ml-5 mt-5">
                      <li>
                        Commission-Free Revenue: Enjoy direct bookings without
                        commission fees.
                      </li>
                      <li>
                        Real-Time Agency Connections: Connect instantly with
                        travel agents.
                      </li>
                      <li>
                        Transparent Deals: Ensure clear agreements with guests
                        and agencies.
                      </li>
                      <li>
                        Advanced Cloud Tools: Manage bookings, track
                        performance, and monitor finances seamlessly.
                      </li>
                    </ul>
                    <p class="max-w-xl mx-auto  text-base leading-relaxed font-bold text-white mt-10">
                      For Travel Agents:
                    </p>
                    <ul className="list-disc text-[#fff] ml-5 mt-5">
                      <li>
                        Instant Hotelier Connections: Access and connect with
                        hoteliers across India.
                      </li>
                      <li>
                        Real-Time Inventory: Showcase and manage large-scale
                        property inventories easily.
                      </li>
                      <li>
                        Direct Traveler Access: Get relevant inquiries without
                        extra commissions.
                      </li>
                      <li>
                        Cloud-Based Solutions: Utilize integrated tools for
                        booking, performance tracking, and more.
                      </li>
                    </ul>

                    <img
                      class="relative z-10 max-w-xs mx-auto -mb-16 md:hidden"
                      src="https://cdn.rareblocks.xyz/collection/celebration/images/contact/4/curve-line-mobile.svg"
                      alt=""
                    />

                    <img
                      class="hidden w-full translate-x-24 translate-y-8 md:block"
                      src="https://cdn.rareblocks.xyz/collection/celebration/images/contact/4/curve-line.svg"
                      alt=""
                    />
                  </div>
                </div>

                <div class="lg:pl-12">
                  <div class="overflow-hidden bg-white rounded-md">
                    <div class="p-6 sm:p-10">
                      <h3 class="text-3xl font-semibold text-black">
                        Register here for free
                      </h3>
                      <p class="mt-4 text-base text-gray-600">
                        Join us today by filling out the registration form below
                        and unlock exclusive benefits!
                      </p>
                      <RegistrationForm />
                    </div>
                  </div>
                </div>

             
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
