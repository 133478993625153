import React, { useEffect } from "react";
import HomePage from "./pages/homepage/HomePage";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AboutUs from "./pages/resources/AboutUs";
import Header from "./components/Header";
import Footer from "./components/footer";
import Features from "./pages/resources/Features";
import Blog from "./pages/resources/blog";
import BecomePartnet from "./pages/resources/BecomePartnet";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <ToastContainer />
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/blogs" element={<Blog />} />
        <Route exact path="/features" element={<Features />} />
        <Route exact path="/partner" element={<BecomePartnet />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
